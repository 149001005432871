.reviews-section {
    font-family: Arial, sans-serif;
  }
  
  .review-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .sort-filter button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .review-item {
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  
  .review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .reviewer-info {
    font-size: 14px;
  }
  
  .reviewer-name {
    font-weight: bold;
  }
  
  .verified {
    color: #28a745;
    margin-left: 10px;
  }
  
  .review-date {
    color: #999;
    margin-left: 20px;
  }
  
  .review-stars {
    color: #FFD700;
    font-size: 18px;
  }
  
  .review-body {
    margin-top: 10px;
  }
  
  .review-body p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .review-image {
    max-width: 150px;
    margin-top: 10px;
    border-radius: 4px;
  }
  
  .review-details p {
    font-size: 12px;
    color: #666;
  }
  
  .review-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .helpful-report button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .likes-dislikes {
    font-size: 14px;
    color: #666;
  }
  
  .seller-response {
    background-color: #f9f9f9;
    border-left: 4px solid #007bff;
    padding: 10px;
    margin-top: 15px;
    font-size: 14px;
    color: #666;
  }
  