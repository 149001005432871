.custom-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 16px; /* Adjust as needed for spacing between items */
  }
  
  .card-title-small {
    font-size: 0.85rem; /* Adjust to your preference */
  }

  /* styles.css */

.custom-card {
    border: 2px solid #ff8c00; /* Assumed orange color for border */
    border-radius: 8px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .custom-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .card-title-small {
    font-size: 0.85rem; /* Smaller font for title */
    color: #333; /* Dark color for text */
  }
  
  /* Optional: for aesthetic padding inside the card body */
  .card-body {
    padding: 10px;
  }