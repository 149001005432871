.product-carousel {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: auto;
  }
  
  .main-product-image-container {
    border: 2px solid #007bff;
    padding: 5px;
    margin-bottom: 15px;
  }
  
  .product-details-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .thumbnail-carousel-container {
    overflow-x: auto; /* Allow horizontal scrolling */
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  }
  
  .thumbnail-carousel-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome/Safari/Opera */
  }
  
  .carousel-track {
    display: flex;
    gap: 10px; /* Space between thumbnails */
  }
  
  .product-details-image-list {
    width: 80px;
    height: 80px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
  }
  
  .product-details-image-list:hover {
    border: 2px solid #007bff;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .left-button {
    left: 0;
  }
  
  .right-button {
    right: 0;
  }
  