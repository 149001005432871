.rating-summary {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    font-family: Arial, sans-serif;
  }
  
  .average-rating {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    text-align: center;
  }
  
  .average-score {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .average-stars {
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  
  .stars {
    color: #FFD700; /* Gold color for stars */
    margin-right: 10px;
  }
  
  .ratings-count {
    font-size: 12px;
    color: #666;
  }
  
  .rating-breakdown {
    width: 100%;
  }
  
  .rating-bar {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .rating-bar .stars {
    font-size: 16px;
    width: 60px;
  }
  
  .bar {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin: 0 10px;
    position: relative;
  }
  
  .filled {
    height: 100%;
    background-color: #FFD700;
    border-radius: 5px;
  }
  
  .count {
    width: 30px;
    text-align: right;
    font-size: 12px;
    color: #666;
  }
  