.notification-dropdown {
    position: absolute;
    top: 70px;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 250px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.notification-item {
    border-bottom: 1px solid #ddd;
}

.notification-item:last-child {
    border-bottom: none;
}

.notification-badge {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    padding: 3px 6px;
    border-radius: 50%;
    font-size: 12px;
}

