/* priceRangeFilter.css */

.filter-section {
  margin-bottom: 20px;
}

.filter-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.price-range {
  font-size: 14px;
  margin-bottom: 10px;
}

.price-input-fields {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 15px;
}

.price-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%; /* Adjust width as needed */
}

.price-field label {
  font-size: 14px;
  margin-bottom: 5px;
}

.price-field input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}
