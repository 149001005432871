/* Base style for the product details button */
.product-details-button {
    background-color: white; /* Default background */
    color: #F46322; /* Default text color */
    padding: 10px 20px;
    font-size: 12px;
    border: 2px solid #F46322;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Hover state */
  .product-details-button:hover {
    background-color: #F46322;
    color: white;
  }
  
  /* Selected state */
  .selected {
    background-color: #F46322; /* Highlight selected background */
    color: white; /* Selected text color */
    border-color: #F46322; /* Highlight selected border */
  }
  
  /* Disabled state for the button (optional, if needed) */
  .product-details-button:disabled {
    background-color: #e0e0e0;
    color: #999;
    cursor: not-allowed;
  }
  