.search-container {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 10px 15px;
    width: 300px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .search-container:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .search-icon {
    color: #aaa;
    margin-right: 10px;
  }
  
  .search-input {
    border: none;
    outline: none;
    font-size: 14px;
    flex-grow: 1;
  }
  
  .search-input::placeholder {
    color: #aaa;
  }
  
  .search-container:hover .search-input::placeholder {
    color: #888;
  }
  